import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React from "react";
import "./style.css";

interface OrderLogsProps {
    data?: any[]
}

export const OrderLogs = ({data}: OrderLogsProps) => {
        const formatTime = (ms: number): string => {
            const totalSeconds = Math.floor(ms / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
        const clmResult = (e: any) => {
            if (e.result === 1)
                return <>Başarılı</>;
            else if (e.result === 2)
                return <>Hatalı</>;
            else
                return <>Unknown : {e.result}</>
        };
        const clmResultErr = (e: any) => {
            if (e.resultErrorType === null)
                return <>-</>;
            else if (e.resultErrorType === 0)
                return <>SYSTEM_ERROR</>;
            else if (e.resultErrorType === 1)
                return <>ACCOUNT_NULL</>;
            else if (e.resultErrorType === 2)
                return <>Aramada bulunamadı</>;
            else if (e.resultErrorType === 3)
                return <>TIMEOUT</>;
            else if (e.resultErrorType === 4)
                return <>FAILED_LOGIN</>;
            else
                return <>Unknown : {e.resultErrorType}</>
        };
        const rowClass = (e: any) => {
            return {
                'success': e.result === 1,
                'error': e.result === 2,
            };
        };
        const clmDuration = (e: any) => {
            return <>{formatTime(e.duration)}</>;
        };

        function formatUnixTimestamp(unixTimestampMs: number): string {
            const date = new Date(unixTimestampMs);

            const formatOptions: Intl.DateTimeFormatOptions = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };

            const formatter = new Intl.DateTimeFormat('tr-TR', formatOptions);
            const formattedParts = formatter.formatToParts(date);

            const formattedDate = formattedParts
                .map(part => part.value)
                .join('')
                .replace(/(\d{2})\.(\d{2})\.(\d{4}),\s/, '$1/$2/$3 ');

            return formattedDate;
        }

        const clmCreateTime = (e: any) => {
            return <>{formatUnixTimestamp(e.createTime)}</>;
        };
        return <>
            <DataTable scrollable scrollHeight="500px" resizableColumns removableSort showGridlines={true} value={data}
                       rowClassName={rowClass}
                       tableStyle={{minWidth: '50rem'}}>
                <Column sortable field="id" header="ID"></Column>
                <Column sortable field="createTime" header="Zaman" body={clmCreateTime}></Column>
                <Column sortable field="domain" header="Domain"></Column>
                <Column sortable field="keyword" header="Keyword"></Column>
                <Column sortable field="resultIndex" header="Sıra"></Column>
                <Column sortable field="proxy" header="Proxy"></Column>
                <Column sortable field="result" header="Result" body={clmResult}></Column>
                <Column sortable field="resultErrorType" header="Error Type" body={clmResultErr}></Column>
                <Column sortable field="duration" body={clmDuration} header="Süre"></Column>
            </DataTable>
        </>
    }
;