import styled from 'styled-components';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Chips} from 'primereact/chips';
import {FloatLabel} from "primereact/floatlabel";
import {Button} from 'primereact/button';
import {useStream} from "../../hooks/Stream";
import {useStreamEvent, useStreamInvoke} from "../../hooks/Stream/hooks";
import OrderHistory from "./OrderHistory";
import {LiveOrders} from "./LiveOrder";
import {Toast} from "primereact/toast";

interface BoxProps {
    breakpoint?: string;
}

const Box = styled.div<BoxProps>`
    margin-top: 25px;
    overflow: hidden;
    border: solid 1px #32383e;
    border-radius: 5px;

    .box-content {
        background: #222529;
        width: 100%;
        padding: 30px;
        min-height: 60px;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 13px 1px #0000002b;
        gap: 20px;
    }

    .box-title {
        width: 100%;
        background-color: #232428;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: solid 1px #33383e;
        color: #b5b5b5;
        letter-spacing: 1px;
    }

    .p-float-label {
        width: 100%;
    }

    .p-float-label input {
        width: 100%;
    }

    .p-float-label .p-component {
        width: 100%;
    }

    .p-float-label .p-chips .p-inputtext {
        width: 100%;
    }

    .p-button {
        height: 33px;
    }

    #targetDomain {
        display: flex;
    }

    #targetDomain input {
        flex: 1;
    }

    #targetDomain button {
        width: 30px;
        height: unset !important;;
    }

    @media (max-width: ${props => props.breakpoint || '1865px'}) {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }
`;

export interface DomainHistory {
    targetDomain: string;
}

const SGADClick = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);


    const stream = useStream();

    useStreamEvent("update_sgadclick_order", (order) => {
        console.log("UPDATE ORDER", order);
    });

    const createOrder = async (e: any) => {
        try {
            if (keywords.length === 0) {
                (toast.current as any).show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Anahtar kelime girilmemiş.',
                    life: 3000
                });
                return;
            }
            setIsLoading(true);
            for (const keyword of keywords) {
                const r = await stream.invoke("createSGADClickOrder", keyword);
                if (r.status === 0) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Hata oluştu',
                        life: 3000
                    });
                }
                if (r.status === 1) {
                    (toast.current as any).show({
                        severity: 'success',
                        summary: 'Başarılı',
                        detail: 'Sipariş oluşturuldu.',
                        life: 3000
                    });
                } else if (r.status === 2) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Yetersiz bakiye.',
                        life: 3000
                    });
                } else if (r.status === 3) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Sistem hazır değil.',
                        life: 3000
                    });
                } else if (r.status === 4) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Sistemsel sorun oluştu.',
                        life: 3000
                    });
                } else if (r.status === 5) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Boş alan veya hatalı format.',
                        life: 3000
                    });
                } else {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Hata oluştu : ' + r.status,
                        life: 3000
                    });
                }
            }

        } catch (err) {
            (toast.current as any).show({
                severity: 'error',
                summary: 'Hata',
                detail: 'Sipariş oluşturulamadı. Server hatası! : ' + err,
                life: 3000
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Toast ref={toast}/>
            <Box>
                <div className="box-title">
                    Yeni sipariş
                </div>
                <div className="box-content">
                    <FloatLabel>
                        <Chips id="keywords" value={keywords} onChange={(e) => setKeywords(e.value as any)}
                               separator=","/>
                        <label htmlFor="keywords">Keywords</label>
                    </FloatLabel>

                    <Button style={{minWidth: '200px'}} onClick={createOrder} label="Analizi Başlat" severity="info"
                            loading={isLoading}/>
                </div>
            </Box>

            <LiveOrders/>

            <OrderHistory setKeywords={setKeywords}/>
        </>
    )
}
export default SGADClick;