import exp from "node:constants";

export enum OrderItemStatus {
    Created,
    AnalyzeQueued,
    AnalyzeStarted,
    AnalyzeCompleted,
    Working,
    Completed,
    CanceledUser,
    CanceledSystem
}

export interface TargetDomain {
    domain: string;
    clickCount: number;
    viewCount: number;
    enabled: boolean;
}

export interface OrderItem {
    id: number;
    clickCount: number;
    createTime: number;
    keyword: string;
    rangeTime: string;
    status: OrderItemStatus;
    targetDomains: TargetDomain[] | null;
    userID: number;
    successClick: number;
    failedClick: number;
    workingCount: number;
    notFoundCount: number;
}

export const isCompletedOrder = (item: OrderItem) => {
    return !(item.status === OrderItemStatus.Created || item.status === OrderItemStatus.AnalyzeQueued || item.status === OrderItemStatus.AnalyzeStarted || item.status === OrderItemStatus.AnalyzeCompleted || item.status === OrderItemStatus.Working);
}