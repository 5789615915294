import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import PrivateRoute from "./components/PrivateRoute";
import {AuthProvider, useAuth} from "./components/AuthContext";
import {PrimeReactProvider} from 'primereact/api';
import "normalize.css";
import "./assets/prime-dark.css";
import "./assets/app.css";
import {Stream} from './hooks/Stream';
import {ProgressSpinner} from "primereact/progressspinner";
import 'primeicons/primeicons.css';
import {PanelAccounts} from "./views/Admin/PanelAccounts";
import {GoogleAccounts} from "./views/Admin/GoogleAccounts";
import {MobileManager} from "./views/Admin/MobileManager";
import SGADClick from "./views/SGADClick";

const Home = React.lazy(() => import('./views/Home'));
const Login = React.lazy(() => import('./views/Login'));
const GADClick = React.lazy(() => import('./views/GADClick'));
const GClick = React.lazy(() => import('./views/GClick'));


function AppRoutes() {
    const {isAuthenticated, isLoading} = useAuth();

    if (isLoading) {
        return (
            <div className="pageLoading">
                <ProgressSpinner/>
            </div>);
    }

    return (
        <>
            <Stream>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={isAuthenticated ? <Navigate to="/"/> : <Login/>}/>
                        <Route path="/" element={<PrivateRoute><Home/></PrivateRoute>}/>
                        <Route path="/gadclick" element={<PrivateRoute><GADClick/></PrivateRoute>}/>
                        <Route path="/sgadclick" element={<PrivateRoute><SGADClick/></PrivateRoute>}/>
                        <Route path="/gclick" element={<PrivateRoute><GClick/></PrivateRoute>}/>
                        {/* ADMIN PAGES */}
                        <Route path="/admin/panelAccounts" element={<PrivateRoute admin={true}><PanelAccounts/></PrivateRoute>}/>
                        <Route path="/admin/googleAccounts" element={<PrivateRoute admin={true}><GoogleAccounts/></PrivateRoute>}/>
                        <Route path="/admin/mobileManager" element={<PrivateRoute admin={true}><MobileManager/></PrivateRoute>}/>
                    </Routes>
                </BrowserRouter>
            </Stream>
        </>
    );
}

function App() {
    return (
        <PrimeReactProvider>
            <AuthProvider>
                <Stream>
                    <AppRoutes/>
                </Stream>
            </AuthProvider>
        </PrimeReactProvider>
    );
}

export default App;