import React, {useEffect, useRef, useState} from 'react';
import {OrderItem, OrderItemStatus, TargetDomain} from "../../domain/models/sgadclick/OrderItem";
import styled from "styled-components";
import {MeterGroup, MeterGroupValue} from "primereact/metergroup";
import {useOrders} from "./hooks/streamOrders";
import {Button} from "primereact/button";
import {useStream} from "../../hooks/Stream";
import {Tag} from "primereact/tag";
import {Tooltip} from "primereact/tooltip";
import {ProgressSpinner} from "primereact/progressspinner";
import {Divider} from "primereact/divider";
import {FloatLabel} from "primereact/floatlabel";
import {Chips} from "primereact/chips";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {Nullable} from "primereact/ts-helpers";
import {Toast} from "primereact/toast";

const Box = styled.div`
    margin-top: 25px;
    overflow: hidden;

    .box-content {
        background: #222529;
        width: 100%;
        align-items: center;
        box-shadow: 0 2px 13px 1px #0000002b;
    }

    .box-title {
        width: 100%;
        background-color: #232428;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: solid 1px #33383e;
        color: #b5b5b5;
        letter-spacing: 1px;
    }

    @media (max-width: 1865px) {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    .p-metergroup-label-list {
        font-size: 10px;
        opacity: 0.5;
    }

    & tr:hover .p-metergroup-label-list {
        opacity: 1;
    }

    & tr td {
        text-align: center;
    }

    & tr:hover {
        background: #2f3036;
    }

    .p-metergroup {
        opacity: 0.8;
    }

    .totalscount {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
    }

    .totalscount span {
        font-size: 13px;
    }

    .orderItems {
        display: flex;
        flex-direction: column;
    }

    .orderItems .orderItem {
        background-color: #202125;
        height: 300px;
        display: flex;
        flex-direction: column;
    }
`;

const OrderBox = styled.div`
    overflow: hidden;
    border: solid 1px #32383e;
    color: #a1a1a1;
    border-radius: 5px;
    margin-top: 15px;

    &:first-child {

    }

    & .title {
        font-weight: bold;
        color: #808080;
        padding: 15px;
        background: linear-gradient(180deg, #2a2c31, #00000000);
        position: relative;
    }

    & .keyword {
        font-weight: bold;
        color: #c8c8c8;
        background: #3b3c40;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 25px;
        height: fit-content;
    }

    & .title .cancelBtn {
        display: flex;
        padding: 5px;
        font-weight: bold;
        background-color: #7c0909b3;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: #949494;
        cursor: pointer;
    }

    & .x-columns {
        display: flex;
        flex: 1;
        padding: 10px;
        padding-left: 15px;
        padding-right: 0px;
    }

    & .x-columns .x-column {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    & .x-columns .x-column.x-det {
        background: #1e1f22;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #353535;
        min-height: 110px;
        margin-right: 15px;
        padding: 10px;
        position: relative;
    }

    & .x-columns .x-column .x-row {
        display: flex;
        flex: 1;
        border-top: solid 1px #353535;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    & .x-columns .x-column .x-row:first-child {
        border-top: none;
    }

    & .x-columns .x-column .x-row .x-key {
        display: flex;
        max-width: 150px;
        flex: 1;
        align-items: center;
        padding-right: 20px;
        color: #717171;
        font-size: 9pt;
    }

    & .x-columns .x-column .x-row .x-val {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-start;
        padding-right: 30px;
    }

    & .activeDomains, .disabledDomains {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-left: 10px;
    }

    & .domainItem {
        flex: 0 1 auto;
        display: flex;
        height: 30px;
        align-items: center;
        background: linear-gradient(0deg, #242730, #444854);
        border-radius: 5px;
        font-size: 10pt;
        padding-left: 6px;
        overflow: hidden;
        border: solid 1px #414446;
    }

    & .domainItem span {
        display: flex;
        height: 30px;
        align-items: center;
        background: #0000003b;
        padding: 5px;
        font-size: 10pt;
        border-left: solid 1px #575757;
        min-width: 40px;
        justify-content: center;
    }

    & .domainItem span:first-child {
        margin-left: 10px;
    }

    & .domainItem span:last-child {
        padding-right: 5px;
    }

    & .domainItem span i {
        margin-right: 5px;
        font-size: 12px;
    }

    & .activeDomains .domainItem {
        background: linear-gradient(0deg, #202329, #2e4e61);
        color: #bebebe;
        border: solid 1px #264d51;
    }


    & .activeDomains .domainItem:hover {
        background: linear-gradient(0deg, #292d35, #35596e);
        color: #cfcfcf;
        border: solid 1px #2f5e63;
        cursor: pointer;
    }

    & .disabledDomains .domainItem:hover {
        background: linear-gradient(0deg, #2d313c, #4e5260);
        color: #cfcfcf;
        border: solid 1px #595d60;
        cursor: pointer;
    }

    & .activeDomains .domainItem span {
        border-left: solid 1px #264d51;
    }

    .p-divider-content {
        font-size: 9pt;
        color: #484848;
        letter-spacing: 1px;
    }

    .x-scrollWrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        max-height: 300px;
        position: absolute;
    }

    .x-v-controls {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .x-h-controls {
        display: flex;
        gap: 10px;
    }

    .x-h-controls > span {
        flex: 1;
    }
`;

interface DomainButtonProps {
    order: OrderItem;
    domain: TargetDomain;
}

const DomainButton: React.FC<DomainButtonProps> = ({order, domain}) => {
    const stream = useStream();
    const click = async () => {
        const r = await stream.invoke("updateSGADClickTargetDomain", order.id, domain.domain, !domain.enabled);
        console.log(r);
    };
    return (
        <>
            <div className="domainItem" onClick={click}>
                {domain.domain.replaceAll("https://www.", "").replaceAll("https://", "")}
                <span>
                    <i className="pi pi-eye" style={{color: '#bebebe'}}></i>
                    {domain.viewCount}
                </span>
                <span>
                    <i className="pi pi-bolt" style={{color: '#bebebe'}}></i>
                    {domain.clickCount}
                </span>
            </div>
        </>
    );
};

const OrderComponent: React.FC<{ order: OrderItem }> = ({order}) => {
        const stream = useStream();
        const toast = useRef(null);
        const [clickCount, setClickCount] = useState<Nullable<number | null>>(0);
        const cities = [
            {name: '00:01:00', minute: 1},
            {name: '00:10:00', minute: 10},
            {name: '00:15:00', minute: 15},
            {name: '01:00:00', minute: 60},
            {name: '02:00:00', minute: 120},
            {name: '03:00:00', minute: 180},
            {name: '04:00:00', minute: 240},
        ];
        const [selectedTimeRange, setSelectedTimeRange] = useState(cities[0]);
        const [isLoading, setIsLoading] = useState(false);
        const runOrder = async (e: any) => {
            try {
                const r = await stream.invoke("runSGADClickOrder", order.id, clickCount, selectedTimeRange.minute);
                if (r.status === 0) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Hata oluştu',
                        life: 3000
                    });
                }
                if (r.status === 1) {
                    (toast.current as any).show({
                        severity: 'success',
                        summary: 'Başarılı',
                        detail: 'Sipariş oluşturuldu.',
                        life: 3000
                    });
                } else if (r.status === 2) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Yetersiz bakiye.',
                        life: 3000
                    });
                } else if (r.status === 3) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Sistem hazır değil.',
                        life: 3000
                    });
                } else if (r.status === 4) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Sistemsel sorun oluştu.',
                        life: 3000
                    });
                } else if (r.status === 5) {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Boş alan veya hatalı format.',
                        life: 3000
                    });
                } else {
                    (toast.current as any).show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Hata oluştu : ' + r.status,
                        life: 3000
                    });
                }
            } catch
                (err) {
                (toast.current as any).show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Sipariş oluşturulamadı. Server hatası! : ' + err,
                    life: 3000
                });
            } finally {
                setIsLoading(false);
            }
        };

        const clmRender_Progress = () => {
            if (order.status === OrderItemStatus.Working) {
                const values: MeterGroupValue[] = [];
                if (order.successClick > 0)
                    values.push({label: 'Başarılı', color: '#34d399', value: order.successClick});
                if (order.notFoundCount > 0)
                    values.push({label: 'Bulunamadı', color: '#fbbf24', value: order.notFoundCount});
                if (order.failedClick > 0)
                    values.push({label: 'Hatalı', color: '#d53a3a', value: order.failedClick});
                if (order.workingCount > 0)
                    values.push({label: 'İşlemde', color: '#1e79e0', value: order.workingCount});

                return <>
                    <div className="x-v-controls w-full">
                        <MeterGroup className="w-full" values={values} max={order.clickCount}/>
                        <div className="x-h-controls" style={{textAlign: "center"}}>
                            {values.map((v) => <span key={v.label + "_" + order.id}
                                                     style={{
                                                         color: v.color,
                                                         marginLeft: "10px"
                                                     }}>{v.label as any} {v.value}</span>)}
                        </div>
                    </div>
                </>;

            } else if (order.status === OrderItemStatus.AnalyzeCompleted) {
                return <>
                    <div className="x-v-controls">
                        <div className="x-h-controls">
                            <FloatLabel>
                                <InputNumber id="clickCount" value={clickCount}
                                             onValueChange={(e) => setClickCount(e.value)}
                                             useGrouping={false}/>
                                <label htmlFor="clickCount">Tık adeti</label>
                            </FloatLabel>

                            <FloatLabel>
                                <Dropdown id="timeRange" value={selectedTimeRange}
                                          onChange={(e) => setSelectedTimeRange(e.value)}
                                          options={cities} optionLabel="name"
                                          placeholder="Select a City" className="w-full md:w-14rem"/>
                                <label htmlFor="timeRange">Zaman aralığı</label>
                            </FloatLabel>
                        </div>
                        <Button style={{minWidth: '200px'}} onClick={runOrder} label="Çalıştır" severity="info"
                                loading={isLoading}/>
                    </div>


                </>;
            } else if (order.status === OrderItemStatus.AnalyzeStarted) {
                return <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>Analaiz
                    yapılıyor<ProgressSpinner
                        style={{width: '50px', height: '50px'}} strokeWidth="5"/></div>;
            } else if (order.status === OrderItemStatus.AnalyzeQueued) {
                return <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>Analaiz
                    yapılıyor<ProgressSpinner
                        style={{width: '50px', height: '50px'}} strokeWidth="5"/></div>;
            } else if (order.status === OrderItemStatus.Created) {
                return <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>Kuyrukta<ProgressSpinner
                    style={{width: '50px', height: '50px'}} strokeWidth="5"/></div>;
            }
        }

        const enabledDomains = order.targetDomains?.filter(x => x.enabled);
        const disabledDomains = order.targetDomains?.filter(x => !x.enabled);

        const clmRender_Domains = () => {
            if (order.status === OrderItemStatus.AnalyzeCompleted || order.status === OrderItemStatus.Working) {
                return <>
                    <div className="x-column x-det">
                        <div className="x-scrollWrapper">
                            {enabledDomains !== undefined && enabledDomains.length > 0 && <Divider align="center" style={{marginTop: "15px"}}>
                                <div className="inline-flex align-items-center">
                                    <b>Hedef {order.status === OrderItemStatus.AnalyzeCompleted ? "Hedef Domainler" : "Hedef Domainler"} ({enabledDomains!.length})</b>
                                </div>
                            </Divider>}
                            <div className="activeDomains">
                                {enabledDomains !== undefined && enabledDomains?.map(x => <DomainButton key={order.id + "_" + x.domain} domain={x}
                                                                        order={order}></DomainButton>)}
                            </div>
                            {disabledDomains !== undefined && disabledDomains!.length > 0 && <Divider align="center" style={{marginTop: "15px"}}>
                                <div className="inline-flex align-items-center">
                                    <b>{order.status === OrderItemStatus.AnalyzeCompleted ? "Tespit Edilen Domainler" : "Tespit edilen diğer domainler"} ({disabledDomains!.length})</b>
                                </div>
                            </Divider>}

                            <div className="disabledDomains">
                                {disabledDomains !== undefined && disabledDomains?.map(x => <DomainButton key={order.id + "_" + x.domain} domain={x}
                                                                         order={order}></DomainButton>)}
                            </div>
                        </div>
                    </div>
                </>;
            } else {
                return <></>
            }
        }

        const [currentTime, setCurrentTime] = useState(Date.now());
        useEffect(() => {
            const timer = setInterval(() => {
                setCurrentTime(Date.now());
            }, 1000);

            return () => clearInterval(timer);
        }, []);

        const formatDuration = (milliseconds: number) => {
            const seconds = Math.floor(milliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);

            return [
                hours.toString().padStart(2, '0'),
                (minutes % 60).toString().padStart(2, '0'),
                (seconds % 60).toString().padStart(2, '0')
            ].join(':');
        };
        const clmRender_Time = () => {
            const createTimeMs = new Date(order.createTime).getTime();
            const elapsedTime = currentTime - createTimeMs;
            return formatDuration(elapsedTime);
        };

        const cancelOrder = async () => {
            const r = await stream.invoke("cancelSGADClickOrder", order.id);
            console.log("cancel result :", r);
        };

        return (
            <OrderBox className="orderItem">
                <Toast ref={toast}/>
                <div className="title">
                    #{order.id}
                    <span style={{marginLeft: "15px"}} className="keyword">{order.keyword}</span>
                    <div className="cancelBtn" onClick={cancelOrder}>x</div>
                </div>

                <div className="x-columns">
                    <div style={{maxWidth: "400px", flex: "1 1 %0", display: "flex", alignItems: "center"}}>
                        <div className="x-column">
                            <div className="x-row">
                                <div className="x-key">Çalışma Süresi</div>
                                <div className="x-val">{clmRender_Time()}</div>
                            </div>

                            <div className="x-row">
                                <div className="x-key">Süre</div>
                                <div className="x-val">{order.rangeTime}</div>
                            </div>
                            <div className="x-row">
                                <div className="x-key">Tıklama</div>
                                <div className="x-val">{order.clickCount}</div>
                            </div>
                        </div>
                    </div>
                    {clmRender_Domains()}
                    <div className="x-column x-det">
                        {clmRender_Progress()}
                    </div>
                </div>
            </OrderBox>
        );
    }
;

export const LiveOrders: React.FC = () => {
    const {orders} = useOrders();
    const stream = useStream();

    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatDuration = (milliseconds: number) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        return [
            hours.toString().padStart(2, '0'),
            (minutes % 60).toString().padStart(2, '0'),
            (seconds % 60).toString().padStart(2, '0')
        ].join(':');
    };

    const cancelOrder = async (order: OrderItem) => {
        const r = await stream.invoke("cancelSGADClickOrder", order.id);
        console.log("cancel result :", r);
    };
    const clmRender_Actions = (item: OrderItem) => {
        return (<>
            <Button icon="pi pi-times" severity="danger" aria-label="Cancel" onClick={() => {
                cancelOrder(item);
            }}/>
        </>);
    };

    //if (orders.length === 0) {
    //    return <></>;
    //}

    return (
        <Box>
            <div className="orderItems">
                {orders.map((o) => <OrderComponent key={o.id} order={o}/>)}
            </div>
        </Box>
    );
};

