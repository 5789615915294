import styled from 'styled-components';
import React, {useCallback, useEffect, useState} from "react";
import useSWR from "swr";
import api from "../../utils/api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Skeleton} from "primereact/skeleton";
import {OrderItem, OrderItemStatus} from "../../domain/models/sgadclick/OrderItem";
import {MeterGroup, MeterGroupValue} from "primereact/metergroup";
import {useStream} from "../../hooks/Stream";
import {Tag} from 'primereact/tag';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {OrderLogs} from "./OrderLogs";
import {ProgressSpinner} from "primereact/progressspinner";

interface BoxProps {
    breakpoint?: string;
}

const Box = styled.div<BoxProps>`
    margin-top: 25px;
    overflow: hidden;
    border: solid 1px #32383e;
    border-radius: 5px;

    .box-content {
        background: #222529;
        width: 100%;
        align-items: center;
        box-shadow: 0 2px 13px 1px #0000002b;
    }

    .box-title {
        width: 100%;
        background-color: #232428;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: solid 1px #33383e;
        color: #b5b5b5;
        letter-spacing: 1px;
    }

    @media (max-width: 1865px) {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    .p-metergroup-label-list {
        font-size: 10px;
        opacity: 0.5;
    }

    & tr:hover .p-metergroup-label-list {
        opacity: 1;
    }

    & tr td {
        text-align: center;
    }

    & tr:hover {
        background: #2f3036;
    }

    .p-metergroup {
        opacity: 0.7;
    }

    .p-tag {
        width: 100%;
        box-shadow: 0 0 8px 0 #00000059;
    }

    & span[data-pc-section="sort"] {
        opacity: 0.5;
    }

    .x-tagetDomains {
        display: block; /* or inline-block */
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        line-height: 1.8em;
        max-width: 350px;
    }

    .x-tagetDomains span {
        background-color: #2e2e2e;
        padding: 2px;
        border-radius: 5px;
        font-size: 9pt;
    }

    table td {
        white-space: break-spaces;
    }

    .xr-keyword {
        position: relative;
        padding-right: 30px;
    }

    .xr-keyword button {
        position: absolute;
        right: 0;
    }
`;

interface OrderHistoryProps {
    setKeywords: React.Dispatch<React.SetStateAction<string[]>>;
}

const OrderHistory: React.FC<OrderHistoryProps> = ({setKeywords}) => {
    const loadingItemsCount = [{}, {}, {}, {}, {}, {}, {}];
    const fecth = async () => {
        try {
            const response = await api.get("/sgadclick/completed-orders");
            return response.data as Array<OrderItem>;
        } catch (e) {
            return new Array<OrderItem>();
        }
    };
    const {error, isLoading, data, mutate} = useSWR("xorderHistory", fecth);
    const {on, off, invoke} = useStream();
    const [logPopup, setLogPopup] = useState(false);
    const [logLoading, setLogLoading] = useState(false);
    const [orderLogs, setOrderLogs] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState<OrderItem | null>(null);

    const onReloadList = useCallback((updatedOrder: OrderItem) => {
        console.log("completed order:", updatedOrder);
        mutate();
    }, []);

    useEffect(() => {
        on('complete_sgadclick_order', onReloadList);
        return () => {
            off('complete_sgadclick_order', onReloadList);
        };
    }, [onReloadList, on, off])

    const clmRender_Progress = (item: OrderItem) => {
        const values: MeterGroupValue[] = [
            //{label: 'Başarılı', color: '#34d399', value: 16},
            //{label: 'Bulunamadı', color: '#fbbf24', value: 8},
            //{label: 'Hatalı', color: '#d53a3a', value: 24},
            //{label: 'İşlemde', color: '#1e79e0', value: 12},
        ];
        if (item.successClick)
            values.push({label: 'Başarılı', color: '#34d399', value: item.successClick});
        if (item.notFoundCount)
            values.push({label: 'Bulunamadı', color: '#fbbf24', value: item.notFoundCount});
        if (item.failedClick)
            values.push({label: 'Hatalı', color: '#d53a3a', value: item.failedClick});
        if (item.workingCount)
            values.push({label: 'İşlemde', color: '#1e79e0', value: item.workingCount});

        return <MeterGroup values={values} max={item.clickCount}/>;
    }

    const clmRender_Status = (item: OrderItem) => {
        if (item.status === OrderItemStatus.Completed)
            return <Tag className="mr-2" icon="pi pi-check" severity="success" value="Başarılı"></Tag>;
        if (item.status === OrderItemStatus.CanceledSystem)
            return <Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Sistem"></Tag>;
        if (item.status === OrderItemStatus.CanceledUser)
            return <Tag icon="pi pi-times" severity="danger" value="İptal Edildi"></Tag>;

        return "UNKNOWN " + item.status;
    }

    function formatUnixTimestamp(unixTimestampMs: number): string {
        const date = new Date(unixTimestampMs);

        const formatOptions: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        const formatter = new Intl.DateTimeFormat('tr-TR', formatOptions);
        const formattedParts = formatter.formatToParts(date);

        const formattedDate = formattedParts
            .map(part => part.value)
            .join('')
            .replace(/(\d{2})\.(\d{2})\.(\d{4}),\s/, '$1/$2/$3 ');

        return formattedDate;
    }

    const [currentTime, setCurrentTime] = useState(Date.now());
    const clmRender_Time = (item: OrderItem) => {
        return formatUnixTimestamp(item.createTime);
    };
    const clmRender_Action = (item: OrderItem) => {
        return <>
            <Button severity={"info"} icon="pi pi-list" style={{opacity: '0.7'}}
                    onClick={() => setSelectedOrder(item)}/>
        </>;
    };
    const clmRender_Domains = (item: OrderItem) => {
        return <>
            <div className="x-tagetDomains">
                {item.targetDomains?.map((x) => <>
                    <span>{x.domain.replaceAll("https://www.", "").replaceAll("https://", "")}</span> </>)}
            </div>
        </>;
    };
    const clmRender_Keyword = (item: OrderItem) => {
        return <>
            <div className="xr-keyword">
                {item.keyword}
                <Button rounded icon="pi pi-pencil" onClick={() => {
                    setKeywords([item.keyword]);
                }}/>
            </div>
        </>;
    };

    useEffect(() => {
        if (selectedOrder != null) {
            setLogPopup(true);
            setLogLoading(true);
            const f = async () => {
                const response = await invoke("getSGADClickOrderLogs", selectedOrder!.id);
                setOrderLogs(response);
                setLogLoading(false);
            }
            f();
        }
    }, [selectedOrder])

    if (isLoading) {
        return <><ProgressSpinner/></>
    }
    if (error) {
        return <>Error</>
    }

    return (
        <>
            <Box>
                <div className="box-title">
                    Sipariş Geçmişi
                </div>
                <div className="box-content">
                    {isLoading ?
                        <DataTable value={loadingItemsCount} showGridlines={true} size="large"
                                   className="p-datatable-striped"
                                   paginator
                                   rows={15}>
                            <Column body={<Skeleton/>} sortable field="id" header="ID" style={{width: '60px'}}></Column>
                            <Column body={<Skeleton/>} header="Progress" style={{width: '25%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="targetDomain" header="Domain"
                                    style={{width: '20%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="keywords" header="Keywords"
                                    style={{width: '20%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="clickCount" header="Adet"
                                    style={{width: '10%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="workingCount" header="Tıklanan"
                                    style={{width: '10%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="rangeTime" header="Süre"
                                    style={{width: '10%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="status" header="Durum"
                                    style={{width: '15%'}}></Column>
                            <Column body={<Skeleton/>} sortable field="createTime" header="Tarih"
                                    style={{width: '10%'}}></Column>
                        </DataTable>
                        :
                        <DataTable value={data} resizableColumns removableSort showGridlines={true} size="large"
                                   className="p-datatable-striped">
                            <Column sortable field="id" header="ID" style={{width: '60px'}}></Column>
                            <Column header="Progress" style={{width: '25%'}} body={clmRender_Progress}></Column>
                            <Column sortable field="targetDomains" header="Domain" body={clmRender_Domains}
                                    style={{width: '20%'}}></Column>
                            <Column sortable field="keyword" header="Keyword" body={clmRender_Keyword}
                                    style={{width: '20%'}}></Column>
                            <Column sortable field="clickCount" header="Adet" style={{width: '10%'}}></Column>
                            <Column sortable field="successClick" header="Tıklanan" style={{width: '10%'}}></Column>
                            <Column sortable field="rangeTime" header="Süre" style={{width: '10%'}}></Column>
                            <Column sortable field="status" header="Durum" style={{width: '15%'}}
                                    body={clmRender_Status}></Column>
                            <Column sortable field="createTime" header="Tarih" body={clmRender_Time}
                                    style={{width: '10%'}}></Column>
                            <Column header="" body={clmRender_Action}
                                    style={{width: '5%'}}></Column>
                        </DataTable>
                    }
                </div>
            </Box>

            <Dialog header="Tıklama detayları" visible={logPopup} dismissableMask={true}
                    style={{width: '50vw', padding: '0'}}
                    className={"pnLogs"}
                    onHide={() => {
                        if (!logPopup) return;
                        setLogPopup(false);
                        setSelectedOrder(null);
                    }}>
                {logLoading ? <ProgressSpinner/> : <OrderLogs data={orderLogs}/>}
            </Dialog>
        </>
    )
}
export default OrderHistory;