import axios from 'axios';
import config from "../config";
import Cookies from "js-cookie";

const api = axios.create({
    baseURL: config().apiUrl,
});

api.interceptors.request.use(
    config => {
        if (Cookies.get("auth-token") !== undefined) {
            config.headers['Authorization'] = `Bearer ${Cookies.get("auth-token")}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;