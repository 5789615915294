import {useEffect, useCallback, useState} from 'react';
import {useStream} from "./index";

export function useStreamEvent(eventName: string, callback: (...args: any[]) => void) {
    const {on, off} = useStream();

    const memoizedCallback = useCallback(callback, [callback]);

    useEffect(() => {
        on(eventName, memoizedCallback);
        return () => off(eventName, memoizedCallback);
    }, [on, off, eventName, memoizedCallback]);
}

export function useStreamSend(methodName: string, ...args: any[]) {
    const {send} = useStream();

    useEffect(() => {
        send(methodName, ...args);
    }, [send, methodName, ...args]);
}

export function useStreamInvoke<T = any>(methodName: string, ...args: any[]) {
    const {invoke} = useStream();
    const [result, setResult] = useState<T | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const executeInvoke = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await invoke<T>(methodName, ...args);
            setResult(response);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An unknown error occurred'));
        } finally {
            setIsLoading(false);
        }
    }, [invoke, methodName, ...args]);

    useEffect(() => {
        executeInvoke();
    }, [executeInvoke]);

    return {result, error, isLoading, refetch: executeInvoke};
}