import * as signalR from "@microsoft/signalr";
import config from "../../config";
import React, {createContext, ReactNode, useContext, useEffect, useState, useCallback, useRef} from "react";
import {useAuth} from "../../components/AuthContext";
import {Static} from "../../utils/static";

interface StreamContextType {
    isConnected: boolean;
    isInitialized: boolean;
    send: (methodName: string, ...args: any[]) => Promise<void>;
    invoke: <T = any>(methodName: string, ...args: any[]) => Promise<T>;
    on: (methodName: string, newMethod: (...args: any[]) => any) => void;
    off: (methodName: string, newMethod: (...args: any[]) => any) => void;
}

const StreamContext = createContext<StreamContextType | undefined>(undefined);

export const Stream = ({children}: { children: ReactNode }) => {
    const authContext = useAuth();
    const [isConnected, setIsConnected] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [pendingCalls, setPendingCalls] = useState<(() => void)[]>([]);
    const eventHandlersRef = useRef<{ [key: string]: ((...args: any[]) => any)[] }>({});

    const executeQueuedCalls = useCallback(() => {
        pendingCalls.forEach(call => call());
        setPendingCalls([]);
    }, [pendingCalls]);

    const waitForConnection = useCallback((callback: () => void) => {
        if (isConnected) {
            callback();
        } else {
            setPendingCalls(prev => [...prev, callback]);
        }
    }, [isConnected]);

    const send = useCallback((methodName: string, ...args: any[]): Promise<void> => {
        return new Promise((resolve, reject) => {
            waitForConnection(() => {
                if (!Static.StreamConnection) {
                    reject(new Error("Connection not established"));
                    return;
                }
                Static.StreamConnection.send(methodName, ...args)
                    .then(resolve)
                    .catch(reject);
            });
        });
    }, [waitForConnection]);

    const invoke = useCallback(<T = any>(methodName: string, ...args: any[]): Promise<T> => {
        return new Promise((resolve, reject) => {
            waitForConnection(() => {
                if (!Static.StreamConnection) {
                    reject(new Error("Connection not established"));
                    return;
                }
                Static.StreamConnection.invoke<T>(methodName, ...args)
                    .then(resolve)
                    .catch(reject);
            });
        });
    }, [waitForConnection]);

    const on = useCallback((methodName: string, newMethod: (...args: any[]) => any) => {
        eventHandlersRef.current[methodName] = [...(eventHandlersRef.current[methodName] || []), newMethod];

        if (Static.StreamConnection) {
            Static.StreamConnection.on(methodName, newMethod);
        }
    }, []);

    const off = useCallback((methodName: string, method: (...args: any[]) => any) => {
        if (eventHandlersRef.current[methodName]) {
            eventHandlersRef.current[methodName] = eventHandlersRef.current[methodName].filter(h => h !== method);
        }

        if (Static.StreamConnection) {
            Static.StreamConnection.off(methodName, method);
        }
    }, []);

    const streamContext: StreamContextType = {
        isConnected,
        isInitialized,
        send,
        invoke,
        on,
        off
    };

    useEffect(() => {
        if (authContext.isAuthenticated && !Static.StreamConnection) {
            Static.StreamConnection = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(config().getHubUrl(), {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .build();

            // Mevcut event handler'ları yeniden bağla
            Object.entries(eventHandlersRef.current).forEach(([methodName, handlers]) => {
                handlers.forEach(handler => {
                    Static.StreamConnection!.on(methodName, handler);
                });
            });

            Static.StreamConnection.onclose(() => {
                setIsConnected(false);
            });

            Static.StreamConnection.onreconnected(() => {
                setIsConnected(true);
                executeQueuedCalls();
            });

            Static.StreamConnection.start()
                .then(() => {
                    setIsConnected(true);
                    setIsInitialized(true);
                    executeQueuedCalls();
                })
                .catch(error => {
                    console.error("Connection failed: ", error);
                    setIsInitialized(true);
                });
        }
    }, [authContext.isAuthenticated, executeQueuedCalls]);

    return (
        <StreamContext.Provider value={streamContext}>
            {children}
        </StreamContext.Provider>
    );
};

export const useStream = () => {
    const context = useContext(StreamContext);
    if (context === undefined) {
        throw new Error('useStream must be used within a Stream');
    }
    return context;
};