import {useState, useEffect, useCallback} from 'react';
import {useStream} from "../../../hooks/Stream";
import {isCompletedOrder, OrderItem} from "../../../domain/models/sgadclick/OrderItem";
import useSWR from "swr";
import api from "../../../utils/api";

export const useOrders = () => {
    const [orders, setOrders] = useState<OrderItem[]>([]);
    const {on, off} = useStream();

    const updateOrder = useCallback((updatedOrder: OrderItem) => {
        setOrders(prevOrders => {
            const existingOrderIndex = prevOrders.findIndex(order => order.id === updatedOrder.id);

            if (isCompletedOrder(updatedOrder)) {
                return prevOrders.filter(order => order.id !== updatedOrder.id);
            }

            if (existingOrderIndex !== -1) {
                return prevOrders.map((order, index) =>
                    index === existingOrderIndex ? {...order, ...updatedOrder} : order
                );
            } else {
                return [...prevOrders, updatedOrder];
            }
        });
    }, []);

    const addOrder = useCallback((newOrder: OrderItem) => {
        if (!isCompletedOrder(newOrder)) {
            setOrders(prevOrders => {
                if (prevOrders.some(order => order.id === newOrder.id)) {
                    return prevOrders;
                }
                return [...prevOrders, newOrder];
            });
        }
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await api.get("/sgadclick/live-orders");
            return response.data as Array<OrderItem>;
        } catch (e) {
            console.error("Error fetching orders:", e);
            return [];
        }
    };

    const {data: initialOrders, error, isLoading} = useSWR("orderLives", fetchOrders);

    useEffect(() => {
        if (initialOrders && !isLoading && !error) {
            setOrders(initialOrders);
        }
    }, [initialOrders, isLoading, error]);

    useEffect(() => {
        if (!isLoading && !error) {
            on('update_sgadclick_order', updateOrder);
            on('create_sgadclick_order', addOrder);

            return () => {
                off('update_sgadclick_order', updateOrder);
                off('create_sgadclick_order', addOrder);
            };
        }
    }, [on, off, updateOrder, addOrder, isLoading, error]);

    return {orders, isLoading, error};
};