import Cookies from "js-cookie";

const prod_config = {
    apiUrl: 'https://netclick.pro/api',
    hubUrl: 'https://netclick.pro/testHub',
    otherSetting: 'value',
    getHubUrl: () => {
        const token = Cookies.get('auth-token');
        return prod_config.hubUrl + "?accessToken=" + token;
    }
};

const dev_config = {
    apiUrl: 'https://localhost:7174/api',
    hubUrl: 'https://localhost:7174/testHub',
    otherSetting: 'value',
    getHubUrl: () => {
        const token = Cookies.get('auth-token');
        return dev_config.hubUrl + "?accessToken=" + token;
    }
};

const config = () => {
    if (process.env.REACT_APP_PROCESS === "prod")
        return prod_config;
    else
        return dev_config;
}

export default config;