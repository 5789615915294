import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from "../AuthContext";
import {LeftMenu} from "../LeftMenu";
import {TopBar} from "../AuthContext/TopBar";
import {ProgressSpinner} from "primereact/progressspinner";


interface PrivateRouteProps {
    children: JSX.Element;
    admin?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children, admin = false}) => {
    const {isAuthenticated, isLoading, userData} = useAuth();
    if (isLoading) {
        return (
            <div>
                <ProgressSpinner/>
            </div>);
    }

    if (isAuthenticated && admin && userData.permission < 1000) {
        return <>
            <LeftMenu/>
            <div className="layout-content">
                <TopBar/>
                <div className="max-content">
                    <div className="center-content">
                        Yetki yok.
                    </div>
                </div>
            </div>
        </>
    }

    return isAuthenticated ?
        <>
            <LeftMenu/>
            <div className="layout-content">
                <TopBar/>
                <div className="max-content">
                    <div className="center-content">
                        {children}
                    </div>
                </div>
            </div>
        </> :
        <Navigate to="/login"/>;
};

export default PrivateRoute;
