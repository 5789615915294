import React, {createContext, useContext, useState, ReactNode, useEffect} from 'react';
import config from "../../config";
import Cookies from "js-cookie";
import api from "../../utils/api";

interface AuthContextType {
    isAuthenticated: boolean;
    isLoading: boolean;
    setLogin: () => void;
    setLogout: () => void;
    checkAuth: () => void;
    userData: TokenUserData;
}

interface TokenUserData {
    id: number;
    username: string;
    permission: number;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({id: 0, username: "", permission: 0});

    const checkAuth = async () => {
        setIsLoading(true);
        try {
            if (Cookies.get("auth-token") === undefined) {
                setIsLoading(false);
                setIsAuthenticated(false)
                return;
            }
            const response = await api.get(config().apiUrl + '/auth/check');
            if (response.status === 200) {
                setUserData(response.data);
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            setIsAuthenticated(false);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        checkAuth();
    }, []);

    const setLogin = async () => {
        setIsAuthenticated(true);
    };

    const setLogout = async () => {
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, isLoading, setLogin, setLogout, checkAuth, userData}}>
            <div className="layout-wrapper">
                {children}
            </div>
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
